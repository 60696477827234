import React, {Component} from 'react';
import './App.css';
import {Button, Container, Segment, Image, Form as SemanticForm, Message, Header, Table, Icon, Divider, Popup, Grid} from 'semantic-ui-react';
import {Formik, Field} from 'formik';
import 'semantic-ui-css/semantic.min.css';
import logo from "./cms.png"
import * as Yup from 'yup';
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import customizable from "./images/shield.svg";
import lightning from "./images/lightning.svg";
import shield from "./images/shield.svg";
import smartphone from "./images/smartphone.svg";
import USPS from 'usps-webtools';
import surgeLogo from "./logo.svg";

Sentry.init({
 dsn: "https://5493296638674193a29c506b4973ca40@sentry.io/1394667"
});
const usps = new USPS({
    server: 'https://secure.shippingapis.com/ShippingAPI.dll',
    userId: '903SURGE1554',
    ttl: 10000 //TTL in milliseconds for request
});
const unitedStates =  [
    { key: 'AL', value: 'AL', text: 'Alabama' },
    { key: 'AK', value: 'AK', text: 'Alaska' },
    { key: 'AZ', value: 'AZ', text: 'Arizona' },
    { key: 'AR', value: 'AR', text: 'Arkansas' },
    { key: 'CA', value: 'CA', text: 'California' },
    { key: 'CO', value: 'CO', text: 'Colorado' },
    { key: 'CT', value: 'CT', text: 'Connecticut' },
    { key: 'DE', value: 'DE', text: 'Delaware' },
    { key: 'DC', value: 'DC', text: 'District Of Columbia' },
    { key: 'FL', value: 'FL', text: 'Florida' },
    { key: 'GA', value: 'GA', text: 'Georgia' },
    { key: 'HI', value: 'HI', text: 'Hawaii' },
    { key: 'ID', value: 'ID', text: 'Idaho' },
    { key: 'IL', value: 'IL', text: 'Illinois' },
    { key: 'IN', value: 'IN', text: 'Indiana' },
    { key: 'IA', value: 'IA', text: 'Iowa' },
    { key: 'KS', value: 'KS', text: 'Kansas' },
    { key: 'KY', value: 'KY', text: 'Kentucky' },
    { key: 'LA', value: 'LA', text: 'Louisiana' },
    { key: 'ME', value: 'ME', text: 'Maine' },
    { key: 'MD', value: 'MD', text: 'Maryland' },
    { key: 'MA', value: 'MA', text: 'Massachusetts' },
    { key: 'MI', value: 'MI', text: 'Michigan' },
    { key: 'MN', value: 'MN', text: 'Minnesota' },
    { key: 'MS', value: 'MS', text: 'Mississippi' },
    { key: 'MO', value: 'MO', text: 'Missouri' },
    { key: 'MT', value: 'MT', text: 'Montana' },
    { key: 'NE', value: 'NE', text: 'Nebraska' },
    { key: 'NV', value: 'NV', text: 'Nevada' },
    { key: 'NH', value: 'NH', text: 'New Hampshire' },
    { key: 'NJ', value: 'NJ', text: 'New Jersey' },
    { key: 'NM', value: 'NM', text: 'New Mexico' },
    { key: 'NY', value: 'NY', text: 'New York' },
    { key: 'NC', value: 'NC', text: 'North Carolina' },
    { key: 'ND', value: 'ND', text: 'North Dakota' },
    { key: 'OH', value: 'OH', text: 'Ohio' },
    { key: 'OK', value: 'OK', text: 'Oklahoma' },
    { key: 'OR', value: 'OR', text: 'Oregon' },
    { key: 'PA', value: 'PA', text: 'Pennsylvania' },
    { key: 'RI', value: 'RI', text: 'Rhode Island' },
    { key: 'SC', value: 'SC', text: 'South Carolina' },
    { key: 'SD', value: 'SD', text: 'South Dakota' },
    { key: 'TN', value: 'TN', text: 'Tennessee' },
    { key: 'TX', value: 'TX', text: 'Texas' },
    { key: 'UT', value: 'UT', text: 'Utah' },
    { key: 'VT', value: 'VT', text: 'Vermont' },
    { key: 'VA', value: 'VA', text: 'Virginia' },
    { key: 'WA', value: 'WA', text: 'Washington' },
    { key: 'WV', value: 'WV', text: 'West Virginia' },
    { key: 'WI', value: 'WI', text: 'Wisconsin' },
    { key: 'WY', value: 'WY', text: 'Wyoming' },
]
const SignupSchema = Yup.object().shape({
    zip: Yup.string()
        .min(5, 'Must be a 5 digit zip code 12345')
        .max(5, 'Must be a 5 digit zip code 12345')
        .required('Required'),
    street: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    city: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required')

});

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoadingIndicator: false,
            showResults: false,
            results: null,
            errorMsg: null,
            trackedAddress: null,
            error: null
        };

        this.handleVerifiedAddress = this.handleVerifiedAddress.bind(this);
        this.handleGoBack = this.handleGoBack.bind(this);

    }

    handleGoBack(){
        this.setState({
            isLoadingIndicator: false,
            showResults: false,
            results: null,
            errorMsg: null,
            trackedAddress: null
        });

    }

    componentDidCatch(error, errorInfo) {
        this.setState({ error });
        Sentry.withScope(scope => {
            Object.keys(errorInfo).forEach(key => {
                scope.setExtra(key, errorInfo[key]);
            });
            Sentry.captureException(error);
        });
    }




    async handleVerifiedAddress(err, address){
        // console.log(address);
        //TODO OBject validation

        try {
            if(address){
                let imbScans = await axios.get(`https://us-central1-surge3mail.cloudfunctions.net/findImb/` + address['zip'] + address['zip4'] + address['delivery_point']);
                // await console.log(address);
                // await console.log("ZIP 11: " + address['zip'] + address['zip4'] + address['delivery_point']);
                await console.dir(imbScans);
                this.setState({
                    isLoadingIndicator: false,
                    showResults: true,
                    results: imbScans,
                    trackedAddress: address

                })
            }else{
            //    TODO: Error catching
                this.setState({
                    isLoadingIndicator: false,
                    showResults: false,
                    results: null,
                    errorMsg: "That is not a valid USPS Address. Please try again."

                })


            }

        }catch(error){
            console.error(address)
            console.error(error)
        }
    }





    render() {
        console.log(window.location.hostname)
        return (
            <div className="App">
                <Container textAlign="center">

                    {window.location.hostname === "track.cmsmailservices.com" ? (
                        <Image size={'small'} src={logo} centered style={{padding: "2em 0"}}/>
                    ) :  <Image size={'small'} src={surgeLogo} centered style={{padding: "2em 0"}}/>}

                    {/*<Image size={'small'} src={surgeLogo} centered style={{padding: "2em 0"}}/>*/}

                    <Segment textAlign={'center'} size='small' padded="very">
                        <Container text>
                        <Header as='h2'>
                            <Header.Content>
                                WELCOME TO THE IMB TRACING WEB APPLICATION
                                <Header.Subheader>This web application was developed to track your company's mailings. To get started, fill out the required info below </Header.Subheader>
                            </Header.Content>
                        </Header>
                        </Container>
                        {this.state.showResults && (this.state.results.data.length > 0) ? (
                                <div>
                                    <Divider />
                                    <Header as='h4'>
                                        <Header.Subheader>
                                            <b>IMB TRACING for:</b> {this.state.trackedAddress.street1}, {this.state.trackedAddress.city}, {this.state.trackedAddress.state} {this.state.trackedAddress.zip}
                                        </Header.Subheader>
                                    </Header>
                                    <Divider />
                                    <Table singleLine textAlign="left" celled striped>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell />
                                                <Table.HeaderCell>Last Scan
                                                    <Popup
                                                    trigger={<Icon size="small" link name="help circle" />}
                                                    content='Date and time when the mailpiece received its handling event (actual, logical, assumed actual, or assumed logical handling event) The date and time value is provided in the time zone of the facility/location where the handling event occurred.'
                                                    position='top center'
                                                    hideOnScroll
                                                    inverted
                                                />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>Current Location
                                                    <Popup
                                                        trigger={<Icon size="small" link name="help circle" />}
                                                        content="City name of the USPS Facility where the mailpiece received its handling event (actual, logical, assumed actual, or assumed logical handling event)"
                                                        position='top center'
                                                        hideOnScroll
                                                        inverted
                                                    />
                                                    </Table.HeaderCell>
                                                <Table.HeaderCell>Start of USPS Possession
                                                    <Popup
                                                        trigger={<Icon size="small" link name="help circle" />}
                                                        content='Date when the USPS took possession of the mail.'
                                                        position='top center'
                                                        hideOnScroll
                                                        inverted
                                                    />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>Expected Delivery
                                                    <Popup
                                                        trigger={<Icon size="small" link name="help circle" />}
                                                        content='USPS-calculated date that indicates when the mailpiece is expected to be delivered. This date is determined based on the published origin entry and destination entry service standards. We will provide the last date of the service standard pair.'
                                                        position='top center'
                                                        hideOnScroll
                                                        inverted
                                                    />
                                                </Table.HeaderCell>

                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                                {this.state.results.data.map((value, index) => {

                                                    if(value){
                                                        let lastScan = null;
                                                        let today = new Date();
                                                        let exDate = value.expected_delivery_date || today;
                                                        let expected = new Date(exDate)
                                                        let delivered = today.getTime() > expected.getTime();
                                                        if(value['scans'][0]){
                                                            lastScan = value['scans'][0];
                                                        }

                                                        console.log(delivered)

                                                        if(lastScan){
                                                            return (

                                                                <Table.Row key={value.piece_id}>
                                                                    <Table.Cell>{index + 1 }</Table.Cell>
                                                                    <Table.Cell>{lastScan['scan_date_time'] || "N/A"}</Table.Cell>
                                                                    <Table.Cell>{lastScan.scan_facility_city || "N/A"}</Table.Cell>
                                                                    <Table.Cell>{value.start_the_clock_date || "N/A"}</Table.Cell>
                                                                    <Table.Cell positive={delivered}>
                                                                        {delivered ? (
                                                                            <Icon name='checkmark' />
                                                                        ) : null}
                                                                        {exDate || "N/A"}
                                                                    </Table.Cell>
                                                                </Table.Row>

                                                            )
                                                        }else{
                                                            return (

                                                                <Table.Row key={value.piece_id}>
                                                                    <Table.Cell>{index + 1 }</Table.Cell>
                                                                    <Table.Cell>{"N/A"}</Table.Cell>
                                                                    <Table.Cell>{ "N/A"}</Table.Cell>
                                                                    <Table.Cell>{value.start_the_clock_date || "N/A"}</Table.Cell>
                                                                    <Table.Cell positive={delivered}>
                                                                        {delivered ? (
                                                                            <Icon name='checkmark' />
                                                                        ) : null}
                                                                        {exDate || "N/A"}
                                                                    </Table.Cell>
                                                                </Table.Row>

                                                            )
                                                        }



                                                    }else{
                                                        return (null)
                                                    }

                                                })}



                                        </Table.Body>
                                    </Table>
                                    {/*TODO: BACK BUTTON*/}
                                    <Button onClick={this.handleGoBack} content="Go Back"/>
                                </div>
                            ) :
                            <div>

                                <br/>
                                <Container text>
                                <Formik
                                    initialValues={{street: "", zip: "", city: "", state: ""}}
                                    validationSchema={SignupSchema}
                                    onSubmit={async (values, actions) => {
                                        await this.setState({ isLoadingIndicator: true, errorMsg: null});
                                        await usps.verify({
                                            street1: values.street,
                                            city: values.city,
                                            state: values.state,
                                            zip: values.zip
                                        }, this.handleVerifiedAddress);
                                        actions.setSubmitting(false);

                                    }}
                                    render={({
                                                 values,
                                                 errors,
                                                 status,
                                                 touched,
                                                 handleBlur,
                                                 handleChange,
                                                 handleSubmit,
                                                 isValidating,
                                                 isSubmitting

                                             }) => (
                                        <SemanticForm widths={"equal"} onSubmit={handleSubmit}
                                                      loading={this.state.isLoadingIndicator} size="small">

                                            <SemanticForm.Group>

                                                <SemanticForm.Input placeholder='Street' name='street' control={Field}
                                                                    error={errors.street && touched.street}/>


                                                <SemanticForm.Input placeholder='City' name='city' control={Field}
                                                                    error={errors.city && touched.city}/>


                                            </SemanticForm.Group>


                                            <SemanticForm.Group>
                                                <SemanticForm.Select placeholder='State' name='state' search onChange={handleChange("state")} onBlur={handleBlur("state")} options={unitedStates} error={errors.state && touched.state} />
                                                <SemanticForm.Input placeholder='Zip Code' name='zip' control={Field}
                                                                    error={errors.zip && touched.zip}/>
                                            </SemanticForm.Group>
                                                <Button type="submit">{isSubmitting ? 'Loading' : 'Submit'}</Button>

                                                {errors.street && touched.street ? (
                                                    <Message icon='attention' size='mini' negative
                                                             content={errors.street}/>
                                                ) : null}

                                                {errors.city && touched.city ? (
                                                    <Message icon='attention' size='mini' negative
                                                             content={errors.city}/>
                                                ) : null}


                                                {errors.state && touched.state ? (
                                                    <Message icon='attention' size='mini' negative
                                                             content={errors.state}/>
                                                ) : null}

                                                {errors.zip && touched.zip ? (
                                                    <Message icon='attention' size='mini' negative
                                                             content={errors.zip}/>
                                                ) : null}


                                        </SemanticForm>
                                    )}
                                />
                                </Container>
                            </div>
                        }
                    </Segment>

                    {this.state.showResults && (this.state.results.data.length < 1) ? (
                        <Segment>
                            {/*{console.log(this.state.results)}*/}
                            <Message icon='attention' size='mini' negative content="That address was not found."/>

                        </Segment>
                    ) : null}

                    {this.state.errorMsg ? (
                        <Segment>
                            <Message icon='attention' size='mini' negative content={this.state.errorMsg}/>

                        </Segment>
                    ) : null}

                    <Segment textAlign="center" vertical padded="very">
                        <Grid columns={4} divided stackable>
                            <Grid.Row>
                                <Grid.Column textAlign="center">
                                    <Image size="tiny" src={customizable} centered />
                                    <Header as="h4">
                                        FULLY CUSTOMIZABLE
                                        <Header.Subheader style={{padding: "0 3em"}}>
                                          The web app is completely customized to your company.
                                        </Header.Subheader>
                                    </Header>
                                </Grid.Column>
                                <Grid.Column textAlign="center">
                                   <Image size="tiny" src={shield} centered />
                                   <Header as="h4">
                                       SAFE & SECURE
                                      <Header.Subheader style={{padding: "0 3em"}}>
                                          All data is encrypted. Your info will be safe and secure.
                                      </Header.Subheader>
                                   </Header>
                                </Grid.Column>
                                <Grid.Column textAlign="center">
                                    <Image centered size="tiny" src={smartphone} />
                                    <Header as="h4">
                                        MOBILE FRIENDLY
                                        <Header.Subheader style={{padding: "0 3em"}}>
                                            This web application is mobile friendly. Use on the go.
                                        </Header.Subheader>
                                    </Header>
                                </Grid.Column>
                                <Grid.Column textAlign="center">
                                    <Image centered size="tiny" src={lightning} />
                                    <Header as="h4">
                                        UPDATED INSTANTLY
                                        <Header.Subheader style={{padding: "0 3em"}}>
                                            Using the latest in technology, IMB data is updated in a second or less.
                                        </Header.Subheader>
                                    </Header>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>


                </Container>
                <Container fluid>
                    <Segment inverted vertical padded>
                        <p>v1.3 beta</p>
                    </Segment>
                </Container>
            </div>

        );
    }
}

export default App;
